import React, { Fragment, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSignedIn, setEmail } from './app/reducers/userSlice';
import { Outlet } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider, Toolbar } from '@mui/material';
import NbfireTheme from './NbfireTheme';
import NavBar from './app/general/NavBar';
import AccountListener from './app/user/AccountListener';
import { GetCognitoToken } from './app/utils/TokenUtils';
import { setUserDetails } from './app/reducers/userDetailsSlice';
import { GET_USER_URL } from './app/utils/UrlUtils';
import axios from 'axios';

const drawerWidth = 240;

const App = () => {
  const email = useSelector(state => state.user.email);
  const dispatch = useDispatch();
  AccountListener();

  const setUserLoginDetails = user => {
    const isSignedIn = user !== undefined;
    dispatch(setIsSignedIn(isSignedIn));
    const email = isSignedIn ? user.attributes.email : "";
    dispatch(setEmail(email));
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(userData => {
      setUserLoginDetails(userData);
    }).catch(() => {
      setUserLoginDetails(undefined);
    });
  });

  const getUser = useCallback(() => {
    GetCognitoToken().then(jwt => {
      axios.get(GET_USER_URL, {
        headers: { "Authorization": `Bearer ${jwt}` },
        crossDomain: true
      }).then((response) => {
        dispatch(setUserDetails(response.data));
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (email) {
      getUser();
    }
  }, [getUser, email]);

  return (
    <Fragment>
      <ThemeProvider theme={NbfireTheme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <NavBar drawerWidth={drawerWidth} />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar sx={{ display: { sm: 'none' } }} />
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
