import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormControlLabel, Typography, useTheme } from '@mui/material';
import { red, green } from '@mui/material/colors';

const NotebookSelector = props => {

  const theme = useTheme();

  const [fileName, setFileName] = useState("");

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    margin: 1,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.palette.grey[500],
    borderStyle: 'solid',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey[600],
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const focusedStyle = {
    borderColor: theme.palette.primary.main
  };

  const acceptStyle = {
    borderColor: green[400],
    backgroundColor: green[50]
  };

  const rejectStyle = {
    borderColor: red[400],
    backgroundColor: red[50]

  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFileName(file.name);
      props.updateNotebook(file);
      props.updateName(file.name);
    })
  // eslint-disable-next-line
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: { 'application/x-ipynb+json': ['.ipynb'] } });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
    // eslint-disable-next-line
  }), [isFocused, isDragAccept, isDragReject]);

  return (
    <Fragment>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} accept=".ipynb" />
          <p>Drag and drop Notebook here, or click to select Notebook</p>
        </div>
      </div>
      <FormControlLabel
        value="Name:"
        control={<Typography variant="body1" sx={{ ml: 2}}>{fileName}</Typography>}
        label="Name:"
        labelPlacement="start"
        sx={{ ml: 0, mt: 2}}
      />
    </Fragment>
  )
};

export default NotebookSelector;