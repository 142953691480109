import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { FormatTimestamp } from '../utils/DateUtils';

const SUBSCRIPTION_DATE_FORMAT = 'YYYYMMDD';
const DISPLAY_DATE_FORMAT = 'MMMM Do YYYY';

const accountDisplay = {
    FREE: "Trial",
    STANDARD: "Tier 1",
    PROFESSIONAL: "Tier 2",
    ADMIN: "Admin",
    TEST: "Test Account"
}

const AccountDetails = props => {
    const { userEmail } = props;
    const userDetails = useSelector(state => state.userDetails.value);

    const subscriptionPeriodStart = userDetails.subscription_period_start ? moment(userDetails.subscription_period_start, SUBSCRIPTION_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : "" ;
    const subscriptionPeriodEnd = userDetails.subscription_period_start ? moment(userDetails.subscription_period_end, SUBSCRIPTION_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : "" ;
    const accountCreatedAt = userDetails.createdAt ? FormatTimestamp(userDetails.createdAt) : "";
    const accountStatus = userDetails.subscription_active !== undefined ? userDetails.subscription_active ? "Active" : "Disabled" : "";

    return (
        <Fragment>
            <Typography variant="h5" sx={{ mb: 2 }}>Account Details</Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1" sx={{ mb: 1 }}>Email: {userEmail || ""}</Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>Account Created: {accountCreatedAt}</Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>Account Status: {accountStatus}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="body1" sx={{ mb: 1 }}>Subscription Type: {userDetails.account_type ? accountDisplay[userDetails.account_type] : ""}</Typography>
                    {subscriptionPeriodStart && <Typography variant="body1" sx={{ mb: 1 }}>Subscription Period Start Date: {subscriptionPeriodStart}</Typography>}
                    {subscriptionPeriodEnd && <Typography variant="body1" sx={{ mb: 1 }}>Subscription Period End Date: {subscriptionPeriodEnd}</Typography>}
                    {userDetails.lifetime_sub && <Typography variant="body1" sx={{ mb: 1 }}>Subscription Legth: Lifetime</Typography>}
                </Grid>
                <Grid item xs={12} sm={4}> 
                    
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default AccountDetails;