import { createSlice } from '@reduxjs/toolkit'

export const notebookDetailSlice = createSlice({
  name: 'notebookDetail',
  initialState: {
    value: {}
  },
  reducers: {
    setNotebookDetail: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setNotebookDetail } = notebookDetailSlice.actions

export default notebookDetailSlice.reducer