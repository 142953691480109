import { createTheme } from '@mui/material/styles';

const NbfireTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#303F9F',
    },
    secondary: {
      main: '#FFCA28',
    }
  },
  typography: {
    fontFamily: [
      'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      'sans-serif'
    ].join(','),
   }
});

export default NbfireTheme;