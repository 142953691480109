import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateRuns } from '../reducers/RunsSlice';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Paper, TextField, Typography } from '@mui/material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { CREATE_RUN_URL } from '../utils/UrlUtils';
import { GetCognitoToken } from '../utils/TokenUtils';
import { useSnackbar } from 'notistack';

const RunNotebook = () => {
    const notebook = useSelector(state => state.notebookDetail.value);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    
    const getInitialParams = () => {
        var initialParams = {};
        notebook.notebookParameters.forEach(element => {
            initialParams[element.name] = "";
        });
        return initialParams;
    }

    const [parameters, setParameter] = useState(getInitialParams());
    const [hideParameters, setHideParameters] = useState(true);

    const updateParameter = (event, paramName) => {
        setParameter({...parameters, [paramName]: event.target.value});
    }

    const updateHideParameters = (event) => {
        setHideParameters(event.target.checked);
    }

    const buildRequestData = () => {
        var requestParams = [];
        notebook.notebookParameters.forEach(element => {
            requestParams.push({"name": element.name, "value": parameters[element.name]});
        });
        const requestData = {
            notebookId: notebook.id,
            parameters: requestParams,
            hideParameters: hideParameters
        };
        return requestData;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        GetCognitoToken().then(jwt =>{
            axios.post(CREATE_RUN_URL, buildRequestData(), {
                headers: {"Authorization" : `Bearer ${jwt}`},
                crossDomain: true
            }).then(response => {
                dispatch(setUpdateRuns(true));
                enqueueSnackbar(`Sucesfully Fired ${notebook.name}`, { variant: 'success' });
            }).catch(error => {
                enqueueSnackbar(`Error Firing ${notebook.name}`, { variant: 'error' });
            });
        });
    };

    return (
        <Fragment>
            <Paper sx={{ padding: 2, mb: 2, height: '100%' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Configure Run</Typography>
                <form onSubmit={handleSubmit}>
                    {
                        notebook.notebookParameters.length > 0 ? notebook.notebookParameters.map(parameter => {
                            return (
                                <TextField 
                                    key={parameter.name} 
                                    id={parameter.name}
                                    name={parameter.name}
                                    label={parameter.name}
                                    type="text"
                                    value={parameters[parameter.name]}
                                    onChange={(event) => updateParameter(event, parameter.name) }
                                    sx={{ mb: 2, width: '100%'}}
                                />
                            );
                        })
                            : <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>No parameters to provide</Typography>
                    }
                    <Box sx={{ flexDirection: 'row' }}>
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={hideParameters} onChange={updateHideParameters} />}
                                    label="Hide Parameters:"
                                    labelPlacement="start"
                                    sx={{ mb: 2, ml: 0 }}
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Button variant="contained" endIcon={<LocalFireDepartmentIcon />} type="submit">
                        Fire Notebook
                    </Button>
                </form>
            </Paper>
        </Fragment>
    );
}

export default RunNotebook;