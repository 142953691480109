import React from 'react';
import axios from 'axios';
import { Button, Container, Dialog, DialogTitle, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setScheduleDeleteOpen } from '../reducers/scheduleDeleteSlice';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { GetCognitoToken } from '../utils/TokenUtils';
import { setUpdateSchedules } from '../reducers/schedulesSlice';
import format from 'string-template';
import { DELETE_SCHEDULE_URL } from '../utils/UrlUtils';
import { useSnackbar } from 'notistack';


const DeleteScheduleDialog = () => {
    const dialogOpen = useSelector(state => state.scheduleDelete.dialogOpen);
    const scheduleId = useSelector(state => state.scheduleDelete.value);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        dispatch(setScheduleDeleteOpen(false));
    };

    const deleteClicked = () => {
        GetCognitoToken().then(jwt =>{
            axios.delete(format(DELETE_SCHEDULE_URL, { scheduleId: scheduleId }), {
                headers: {"Authorization" : `Bearer ${jwt}`},
                crossDomain: true
            }).then(response => {
                dispatch(setUpdateSchedules(true));
                handleClose();
                enqueueSnackbar(`Deleted Schedule`, { variant: 'success' });
            }).catch(error => {
                enqueueSnackbar(`Error Deleting Schedule`, { variant: 'error' });
            });
        });
    }

    return (
        <Dialog onClose={handleClose} open={dialogOpen} >
            <DialogTitle>Delete Schedule</DialogTitle>
            <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="success" startIcon={<CheckCircleRoundedIcon />} sx={{ mb: 2 }} autoFocus onClick={deleteClicked}>
                        Confirm
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="error" endIcon={<CancelRoundedIcon />} sx={{ mb: 2 }} onClick={handleClose} >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            </Container>
        </Dialog>
    );
}

export default DeleteScheduleDialog;