
export const GET_NOTBOOKS_URL = process.env.REACT_APP_NBFIRE_URL + "/notebooks";
export const ADD_NOTBOOK_URL = process.env.REACT_APP_NBFIRE_URL + "/notebooks";
export const GET_NOTBOOK_URL = process.env.REACT_APP_NBFIRE_URL + "/notebooks/{notebookId}";
export const CREATE_RUN_URL = process.env.REACT_APP_NBFIRE_URL + "/runs";
export const GET_RUNS_URL = process.env.REACT_APP_NBFIRE_URL + "/runs";
export const GET_OUTPUT_NOTEBOOK_URL = process.env.REACT_APP_NBFIRE_URL + "/runs/{runId}/notebook";
export const CREATE_SCHEDULE_URL = process.env.REACT_APP_NBFIRE_URL + "/schedules";
export const GET_SCHEDULES_URL  = process.env.REACT_APP_NBFIRE_URL + "/schedules";
export const DELETE_SCHEDULE_URL = process.env.REACT_APP_NBFIRE_URL + "/schedules/{scheduleId}";
export const UPDATE_SCHEDULE_URL = process.env.REACT_APP_NBFIRE_URL + "/schedules/{scheduleId}";
export const GET_USER_URL = process.env.REACT_APP_NBFIRE_URL + "/user";
export const GET_ACCOUNT_TYPES_URL = process.env.REACT_APP_NBFIRE_URL + "/accounts";