import cronstrue from 'cronstrue';

export const getCronExpression = cron => {
    try {
        const cronParts = cron.split(' ');
        if (cronParts.length === 5) {
            const cronString = cronstrue.toString(cron);
            return cronString;
        } else {
            return "Invalid Cron expression, 5 parts required";
        }
    } catch (_) {
        return "Invalid Cron expression";
    }
}