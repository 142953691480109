import React, { Fragment } from 'react';
import { Paper, Typography, Grid, Box, Stack, ListItem, ListItemText, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';

const NotebookMetadataDisplay = props => {
    const { notebook, includeDetail = true } = props;
    return (
        <Paper sx={{ padding: 2, mb: 2, height: '100%', bgcolor: 'primary.main', color: 'white' }}>
            <Grid container spacing={2}>
                <Grid item xs={11} key={notebook.id + "name"}>
                    <Typography variant="h6" sx={{ mb: 1, wordBreak: 'break-all' }}>{notebook.name}</Typography>
                </Grid>
                <Grid item xs={1} key={notebook.id + "state"} >
                    <Box display="flex" justifyContent="flex-end">
                        {notebook.state === "AWAITING_UPLOAD" && <PendingIcon size={20} sx={{ color: 'white' }} />}
                        {notebook.state === "PROCESSING" && <CircularProgress disableShrink size={20} sx={{ color: 'white' }} />}
                        {notebook.state === "READY" && <CheckCircleIcon size={20} sx={{ color: 'white' }} />}
                        {notebook.state === "ERROR" && <CancelIcon size={20} sx={{ color: 'white' }} />}
                    </Box>
                </Grid>
            </Grid>
            { includeDetail && 
                <Fragment>
                    { notebook.description && <Typography variant="body2" sx={{ mb: 1 }}><b>Description:</b> {notebook.description}</Typography>}
                    <Typography variant="body2" sx={{ mb: 1 }}><b>State:</b> {notebook.state}</Typography>
                    { notebook.notebookParameters &&
                        <Fragment>
                            <Typography variant="body2"><b>Notebook Parameters:</b></Typography>
                            <Stack direction="row" spacing={2} justifyContent="flex-start" flexWrap="wrap">
                                {
                                    notebook.notebookParameters.length > 0 ? notebook.notebookParameters.map(parameter => {
                                        return (
                                            <Box key={"metadata-" + parameter.name}><ListItem><ListItemText primary={parameter.name}/></ListItem> </Box>
                                        );
                                    })
                                        : <Typography variant="body2" sx={{ m: 1 }}>No parameters</Typography>
                                }
                            </Stack>
                        </Fragment>
                    }
                    { notebook.packages &&
                        <Fragment>
                            <Typography variant="body2"><b>Notebook Packages:</b></Typography>
                            <Stack direction="row" spacing={2} justifyContent="flex-start" flexWrap="wrap">
                                {
                                    notebook.packages.length > 0 ? notebook.packages.map(packageName => {
                                        return (
                                            <Box key={"metadata-" + packageName}><ListItem><ListItemText primary={packageName}/></ListItem> </Box>
                                        );
                                    })
                                        : <Typography variant="body2" sx={{ m: 1 }}>No packages</Typography>
                                }
                            </Stack>
                        </Fragment>
                    }
                    { notebook.error_message &&
                        <Alert variant="outlined" severity="error">
                            {notebook.error_message}
                        </Alert>
                    }
                </Fragment>
            }
        </Paper>
    );
}

export default NotebookMetadataDisplay;