import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { GetCognitoToken } from '../utils/TokenUtils';
import { CREATE_SCHEDULE_URL } from '../utils/UrlUtils';
import { getCronExpression } from './ScheduleUtils';
import { setUpdateSchedules } from '../reducers/schedulesSlice';
import ScheduleBuilder from './ScheduleBuilder';
import { useSnackbar } from 'notistack';

const CreateSchedule = () => {
    const notebook = useSelector(state => state.notebookDetail.value);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const getInitialParams = () => {
        var initialParams = {};
        notebook.notebookParameters.forEach(element => {
            initialParams[element.name] = "";
        });
        return initialParams;
    }

    const timeZoneData = [
        "America/New_York", "America/Chicago", "America/Los_Angeles", "Pacific/Honolulu",
        "Europe/London", "Europe/Paris", "Europe/Istanbul", 
        "Asia/Kolkata", "Asia/Shanghai", "Asia/Hong_Kong", "Asia/Jakarta", "Asia/Taipei", "Asia/Tokyo",
        "Australia/Perth", "Australia/Sydney", "Pacific/Auckland", "Pacific/Fiji",
        "America/Sao_Paulo", "America/Santiago"   
    ];

    const [cronSchedule, setCronSchedule] = useState("0 7 * * ?");

    const [isEnabled, setIsEnabled] = useState(true);
    const [parameters, setParameter] = useState(getInitialParams());
    const [timeZone, setTimeZone] = useState("Europe/London");
    const [hideParameters, setHideParameters] = useState(true);

    const updateIsEnabled = (event) => {
        setIsEnabled(event.target.checked);
    }

    const updateParameter = (event, paramName) => {
        setParameter({ ...parameters, [paramName]: event.target.value });
    }

    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
    };

    const updateHideParameters = (event) => {
        setHideParameters(event.target.checked);
    }

    const buildRequestData = () => {
        var requestParams = [];
        notebook.notebookParameters.forEach(element => {
            requestParams.push({ "name": element.name, "value": parameters[element.name] });
        });
        const requestData = {
            notebookId: notebook.id,
            schedule: cronSchedule,
            timezone: timeZone,
            isEnabled: isEnabled,
            parameters: requestParams,
            hideParameters: hideParameters
        };
        return requestData;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        GetCognitoToken().then(jwt => {
            axios.post(CREATE_SCHEDULE_URL, buildRequestData(), {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then(response => {
                dispatch(setUpdateSchedules(true));
                enqueueSnackbar(`Sucesfully Fired Schedule For ${notebook.name}`, { variant: 'success' });
            }).catch(error => {
                enqueueSnackbar(`Error Firing Schedule For ${notebook.name}`, { variant: 'error' });
            });
        });
    };

    return (
        <Fragment>
            <Paper sx={{ padding: 2, mb: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Configure Schedule</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={7}>
                            <Typography variant="body1" sx={{ mb: 1 }}>Schedule:</Typography>
                            <ScheduleBuilder cronSchedule={cronSchedule} setCronSchedule={setCronSchedule} />
                            <Box component="div" sx={{ whiteSpace: 'normal', mb: 1 }}>
                                <Typography variant="caption">
                                    {getCronExpression(cronSchedule)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="body1" sx={{ mb: 1 }}>Timezone:</Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="time-zone-select"
                                    value={timeZone}
                                    onChange={handleTimeZoneChange}
                                    sx={{ mb: 2 }}
                                >
                                    {timeZoneData.map(function (zone) {
                                        return (
                                            <MenuItem key={`time-zone-${zone}`} value={zone}>{zone}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <Typography variant="body1" sx={{ mb: 1 }}>Parameters:</Typography>
                            {
                                notebook.notebookParameters.length > 0 ? notebook.notebookParameters.map(parameter => {
                                    return (
                                        <TextField
                                            key={parameter.name}
                                            id={parameter.name}
                                            name={parameter.name}
                                            label={parameter.name}
                                            type="text"
                                            value={parameters[parameter.name]}
                                            onChange={(event) => updateParameter(event, parameter.name)}
                                            sx={{ mb: 2, width: '100%' }}
                                        />
                                    );
                                })
                                    : <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>No parameters to provide</Typography>
                            }
                            <Box sx={{ flexDirection: 'row' }}>
                                <FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={isEnabled} onChange={updateIsEnabled} />}
                                            label="Enabled:"
                                            labelPlacement="start"
                                            sx={{ mb: 2, ml: 0 }}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                            <Box sx={{ flexDirection: 'row' }}>
                                <FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={hideParameters} onChange={updateHideParameters} />}
                                            label="Hide Parameters:"
                                            labelPlacement="start"
                                            sx={{ mb: 2, ml: 0 }}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button variant="contained" endIcon={<ScheduleIcon />} type="submit">
                        Fire Schedule
                    </Button>
                </form>
            </Paper>
        </Fragment>
    );
}

export default CreateSchedule;