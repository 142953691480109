import { createSlice } from '@reduxjs/toolkit'

export const accountTypesSlice = createSlice({
  name: 'accountTypes',
  initialState: {
    value: []
  },
  reducers: {
    setAccountTypes: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setAccountTypes } = accountTypesSlice.actions

export default accountTypesSlice.reducer