import React, { Fragment, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setNotebooks, setUpdateNotebooks } from '../reducers/notebooksSlice';
import { Grid } from '@mui/material';
import { GET_NOTBOOKS_URL } from '../utils/UrlUtils';
import { GetCognitoToken } from '../utils/TokenUtils';
import NotebookMetadataDisplay from './NotebookMetadataDisplay';
import { compareByLastModifiedEpoch } from '../utils/DateUtils';
import { useSnackbar } from 'notistack';

const POLLING_TIME = 10000 // 10s

const NotebooksSummary = () => {
    const notebooks = useSelector(state => state.notebooks.value);
    var sortedNotebooks = [...notebooks];
    sortedNotebooks.sort(compareByLastModifiedEpoch);
    const updateNotebooks = useSelector(state => state.notebooks.updateNotebooks);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const notebookProcessingCheck = useCallback(notebooks => {
        if(notebooks) {
            const isprocessing = notebooks.some(notebook => {
                return notebook.state === "PROCESSING" || notebook.state === "AWAITING_UPLOAD";
            });
            if(isprocessing) {
                const timer = setTimeout(() => {
                    dispatch(setUpdateNotebooks(true));
                }, POLLING_TIME);
                return () => clearTimeout(timer);
            }
        }
    }, [dispatch]);

    const getNotebooks = useCallback(() => {
        GetCognitoToken().then(jwt => {
            axios.get(GET_NOTBOOKS_URL, {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then(response => {
                const notebooks = response.data.Items;
                dispatch(setNotebooks(notebooks));
                dispatch(setUpdateNotebooks(false));
                notebookProcessingCheck(notebooks);
            }).catch(error => {
                enqueueSnackbar(`Error Fetching Notebooks`, { variant: 'error' });
            });
        });
    }, [dispatch, enqueueSnackbar, notebookProcessingCheck]);

    useEffect(() => {
        getNotebooks();
    }, [getNotebooks]);

    useEffect(() => {
        if (updateNotebooks) {
            getNotebooks();
        }
    }, [updateNotebooks, getNotebooks]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                {
                    sortedNotebooks.map(notebook => {
                        return (
                            <Grid item xs={12} sm={12} md={12} lg={4} key={notebook.id}>
                                <Link to={"/notebooks/" + notebook.id} style={{ textDecoration: 'none' }}>
                                    <NotebookMetadataDisplay notebook={notebook} />
                                </Link>
                            </Grid>);
                    })
                }
            </Grid>
        </Fragment>
    );
}

export default NotebooksSummary;