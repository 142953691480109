import React, { Fragment } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import NotebooksSummary from './NotebooksSummary';
import { Link, Outlet } from 'react-router-dom';

const Notebooks = () => {
    return (
        <Fragment>
            <Outlet />
            <Paper sx={{ padding: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Notebooks
                    <Button component={Link} to="/notebooks/add">Upload</Button>
                </Typography>
                <NotebooksSummary />
            </Paper>
        </Fragment>
    );
}

export default Notebooks;