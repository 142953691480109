import { Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { setEmail, setIsSignedIn } from '../reducers/userSlice';

const AccountListener = () => {
    const dispatch = useDispatch();

    const setUserDetails = user => {
        const isUserSignedIn = user !== undefined;
        dispatch(setIsSignedIn(isUserSignedIn));
        const userEmail = isUserSignedIn ? user.email : "";
        dispatch(setEmail(userEmail));
    }

    const userEventListener = (data) => {

        switch (data.payload.event) {
            case 'signIn':
                setUserDetails(data.payload.data.attributes);
                break;
            case 'signUp':
                setUserDetails(data.payload.data.attributes);
                break;
            case 'signOut':
                setUserDetails(undefined);
                break;
            case 'signIn_failure':
                setUserDetails(undefined);
                break;
            default:
                // NO-OP
                break;
        }
    }

    Hub.listen('auth', userEventListener);

}

export default AccountListener;