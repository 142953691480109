import { createSlice } from '@reduxjs/toolkit'

export const notebooksSlice = createSlice({
  name: 'notebooks',
  initialState: {
    value: [],
    updateNotebooks: false
  },
  reducers: {
    setNotebooks: (state, action) => {
      state.value = action.payload
    },
    setUpdateNotebooks: (state, action) => {
      state.updateNotebooks = action.payload
    }
  }
})

export const { setNotebooks, setUpdateNotebooks } = notebooksSlice.actions

export default notebooksSlice.reducer