import React, { Fragment, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { setNotebookDetail } from '../reducers/notebookDetailSlice';
import { useParams } from "react-router-dom";
import format from 'string-template';
import { GET_NOTBOOK_URL } from '../utils/UrlUtils';
import { GetCognitoToken } from '../utils/TokenUtils';
import NotebookMetadataDisplay from './NotebookMetadataDisplay';
import { Alert, Grid } from '@mui/material';
import RunNotebook from '../runs/RunNotebook';
import RunsSummary from '../runs/RunsSummary';
import CreateSchedule from '../schedules/CreateSchedule';
import SchedulesSummary from '../schedules/SchedulesSummary';

const NotebookDetail = () => {
    const { id } = useParams();
    const getNotebookUrl = format(GET_NOTBOOK_URL, { notebookId: id });

    const notebook = useSelector(state => state.notebookDetail.value);
    const dispatch = useDispatch();

    const getNotebook = useCallback(() => {
        GetCognitoToken().then(jwt => {
            axios.get(getNotebookUrl, {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then(response => {
                const notebookDetail = response.data;
                dispatch(setNotebookDetail(notebookDetail));
            });
        });
    }, [getNotebookUrl, dispatch]);

    useEffect(() => {
        getNotebook();
    }, [getNotebook]);

    return (
        <Fragment>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <NotebookMetadataDisplay notebook={notebook} includeDetail={false}/>
                    </Grid>
                </Grid>
                { notebook.state === "READY" &&
                    <Fragment>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={7}>
                                <CreateSchedule />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <SchedulesSummary isNotebookRequest={true} />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <RunNotebook />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <RunsSummary isNotebookRequest={true} />
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                {notebook.state === "PROCESSING" && <Grid container item spacing={2}><Grid item xs={12} sm={12}><Alert severity="info">Notebook still processing</Alert></Grid></Grid>}
                {notebook.state === "ERROR" && <Grid container item spacing={2}><Grid item xs={12} sm={12}><Alert severity="error">Notebook invalid, unable to run or Schedule</Alert></Grid></Grid>}
            </Grid>
        </Fragment>
    );
}

export default NotebookDetail;