import { createSlice } from '@reduxjs/toolkit'

export const runsSlice = createSlice({
  name: 'runs',
  initialState: {
    value: [],
    updateRuns: false
  },
  reducers: {
    setRuns: (state, action) => {
      state.value = action.payload
    },
    setUpdateRuns: (state, action) => {
      state.updateRuns = action.payload
    }
  }
})

export const { setRuns, setUpdateRuns } = runsSlice.actions

export default runsSlice.reducer