import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { closeSnackbar, SnackbarProvider } from 'notistack';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';
import Homepage from './app/general/Homepage';
import Notebooks from './app/notebooks/Notebooks';
import NotebookDetail from './app/notebooks/NotebookDetail';
import AddNotebook from './app/notebooks/AddNotebook';
import UserAccount from './app/user/UserAccount';
import { Button } from '@mui/material';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider action={(snackbarId) => (<Button sx={{color: "white"}} onClick={() => closeSnackbar(snackbarId)}>Dismiss</Button>)} anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="/" element={<Homepage />} />
              <Route path="/notebooks" element={<Notebooks />}>
                <Route path="add" element={<AddNotebook/>}/>
              </Route>
              <Route path="notebooks/:id" element={<NotebookDetail />}/>
              <Route path="/account" element={<UserAccount />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);