import React, { useEffect, useCallback, Fragment, useState, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setSchedules, setUpdateSchedules } from '../reducers/schedulesSlice';
import { GetCognitoToken } from '../utils/TokenUtils';
import { GET_SCHEDULES_URL, UPDATE_SCHEDULE_URL } from '../utils/UrlUtils';
import { Box, Checkbox, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getCronExpression } from './ScheduleUtils';
import DeleteScheduleDialog from './DeleteScheduleDialog';
import { setScheduleDeleteOpen, setScheduleToDelete } from '../reducers/scheduleDeleteSlice';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import format from 'string-template';
import { compareByEpoch } from '../utils/DateUtils';
import { useSnackbar } from 'notistack';

const ScheduleRow = (props) => {
  const { row, isNotebookRequest } = props;
  const [open, setOpen] = React.useState(false);
  const [hideParameters, setHideParameters] = React.useState(row.hideParameters !== undefined ? row.hideParameters : true);
  const isFirstRender = useRef(true);
  const updateSchedules = useSelector(state => state.schedules.updateSchedules);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const deleteClicked = () => {
    dispatch(setScheduleToDelete(row.id));
    dispatch(setScheduleDeleteOpen(true));
  }

  const disableClicked = () => {
    updateSchedule(false, false);
  }

  const enableClicked = () => {
    updateSchedule(true, false);
  }

  const updateHideParams = (event) => {
    setHideParameters(event.target.checked);
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateSchedule(row.isEnabled, true);
    // eslint-disable-next-line
  }, [hideParameters]);

  const updateSchedule = (isEnabled, isHideParamsChange) => {
    const requestData = {
      notebookId: row.notebookId,
      schedule: row.schedule,
      timezone: row.timezone,
      isEnabled: isEnabled,
      parameters: row.parameters,
      hideParameters: hideParameters
    };

    GetCognitoToken().then(jwt => {
      axios.put(format(UPDATE_SCHEDULE_URL, { scheduleId: row.id }), requestData, {
        headers: { "Authorization": `Bearer ${jwt}` },
        crossDomain: true
      }).then(response => {
        dispatch(setUpdateSchedules(true));
        if(isHideParamsChange) {
          let successMessage = `Hide Parameters Selection Updated`;
          enqueueSnackbar(successMessage, { variant: 'success' });
        } else {
          let successMessage = isEnabled ? `Enabled Schedule` : `Disabled Schedule`;
          enqueueSnackbar(successMessage, { variant: 'success' });
        }
      }).catch(error => {
        let failureMessage = isEnabled ? `Failed to Enable Schedule` : `Failed to Disable Schedule`;
        enqueueSnackbar(failureMessage, { variant: 'error' });
      });
    });
  }

  return (
    <Fragment>
      {row &&
        <Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {!isNotebookRequest && <TableCell scope="row">{row.notebookId}</TableCell>}
            <TableCell>{getCronExpression(row.schedule)}</TableCell>
            <TableCell>
              <Checkbox checked={hideParameters} onChange={updateHideParams} />
            </TableCell>
            <TableCell>{row.timezone}</TableCell>
            <TableCell>{row.isEnabled ?
              <IconButton color="success" aria-label="Enabled" disabled={updateSchedules} sx={{ p: 0 }} onClick={disableClicked}>
                <ToggleOnIcon sx={{ fontSize: "32px" }} />
              </IconButton>
              :
              <IconButton color="error" aria-label="Disabled" disabled={updateSchedules} sx={{ p: 0 }} onClick={enableClicked}>
                <ToggleOffIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            }
            </TableCell>
            <TableCell align={"right"}>
              <IconButton color="primary" aria-label="Delete Schedule" onClick={deleteClicked}>
                <DeleteForeverIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                { row.parameters && row.parameters.length > 0 ?
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="body1" gutterBottom component="div">Parameters:</Typography>
                    <Table size="small" aria-label="parameters">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.parameters && row.parameters.map((param) => (
                          <TableRow key={param.name}>
                            <TableCell scope="row">{param.name}</TableCell>
                            <TableCell>{param.value}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  :
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="body2" gutterBottom component="div">Notebook does not have any parameters</Typography>
                  </Box>
                }
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      }
    </Fragment>
  );
}

const SchedulesSummary = props => {
  const { isNotebookRequest } = props;

  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const notebook = useSelector(state => state.notebookDetail.value);
  const schedules = useSelector(state => state.schedules.value);
  var sortedSchedules = [...schedules];
  sortedSchedules.sort(compareByEpoch);
  const updateSchedules = useSelector(state => state.schedules.updateSchedules);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getSchedules = useCallback(() => {
    GetCognitoToken().then(jwt => {
      axios.get(GET_SCHEDULES_URL, {
        headers: { "Authorization": `Bearer ${jwt}` },
        crossDomain: true,
        params: {
          ...(isNotebookRequest && { notebookId: notebook.id })
        }
      }).then(response => {
        const schedules = response.data.Items;
        dispatch(setSchedules(schedules));
        dispatch(setUpdateSchedules(false));
      }).catch(error => {
        enqueueSnackbar(`Error Fetching Schedules`, { variant: 'error' });
      });
    });
  }, [dispatch, isNotebookRequest, enqueueSnackbar, notebook.id]);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    if (updateSchedules) {
      getSchedules();
    }
  }, [updateSchedules, getSchedules]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedSchedules.length) : 0;

  return (
    <Paper sx={{ padding: 2, height: '100%' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Schedules</Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.default }}>
        <Table aria-label="collapsible table" size="small" >
          <TableHead>
            <TableRow sx={{ "& th": { fontWeight: 600 } }}>
              <TableCell />
              {!isNotebookRequest && <TableCell>Notebook ID</TableCell>}
              <TableCell>Schedule</TableCell>
              <TableCell>Hide Params</TableCell>
              <TableCell>Timezone</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSchedules && sortedSchedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((schedule) => (
              <ScheduleRow key={schedule.id} row={schedule} isNotebookRequest={isNotebookRequest} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={!isNotebookRequest ? 5 : 4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedSchedules.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteScheduleDialog />
    </Paper>
  );
}

export default SchedulesSummary;