import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import MenuIcon from '@mui/icons-material/Menu';
import UploadIcon from '@mui/icons-material/Upload';
import UserMenu from './UserMenu';

const NavBar = (props) => {
    const { window, drawerWidth } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const isSignedIn = useSelector(state => state.user.isSignedIn);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar>
                <Button variant="text" component={Link} to="/">
                    <Typography variant="h4" noWrap component="div" sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}>
                        NBFire
                    </Typography>
                </Button>
            </Toolbar>
            <Divider />
            <Box>
                <UserMenu width={drawerWidth - 1}/>
            </Box>
            <Divider />
            { isSignedIn &&
                <Fragment>
                    <List>
                        <ListItem button component={Link} to="/notebooks" sx={{ pb: 0 }}>
                            <ListItemIcon>
                                <CodeIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary="Notebooks" />
                        </ListItem>
                        <ListItem button component={Link} to="/notebooks/add" sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <UploadIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary="Upload" />
                        </ListItem>
                    </List>
                    <Divider />
                </Fragment>
            }
        </div>
    );

    return (
        <Fragment>
            <AppBar
                position="fixed"
                sx={{
                    display: { sm: 'none' }
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                        NBFire
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    PaperProps={{
                        sx: {
                            color: 'white',
                        }
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                            color: 'white',
                        }
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Fragment>
    );
}

export default NavBar;