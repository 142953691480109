import { createSlice } from '@reduxjs/toolkit'

export const scheduleDeleteSlice = createSlice({
  name: 'scheduleDelete',
  initialState: {
    value: "",
    dialogOpen: false
  },
  reducers: {
    setScheduleToDelete: (state, action) => {
      state.value = action.payload
    },
    setScheduleDeleteOpen: (state, action) => {
      state.dialogOpen = action.payload
    }
  }
})

export const { setScheduleToDelete, setScheduleDeleteOpen } = scheduleDeleteSlice.actions

export default scheduleDeleteSlice.reducer