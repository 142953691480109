import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Paper, Typography } from '@mui/material';
import NotebooksSummary from '../notebooks/NotebooksSummary';
import UserAccount from '../user/UserAccount';
import moment from 'moment';

const DATE_FORMAT = 'YYYYMMDD';

const Homepage = () => {
    const isSignedIn = useSelector(state => state.user.isSignedIn);
    const userDetails = useSelector(state => state.userDetails.value);

    if(isSignedIn && (userDetails.account_type === "FREE")) {
        var trialStart = new Date(userDetails.createdAt);
        var trialFinish = new Date(trialStart.setMonth(trialStart.getMonth() + 1));
        var periodEnd = moment(trialFinish).format(DATE_FORMAT);

        var now = new Date();
        var nowFormatted = moment(now).format(DATE_FORMAT);

        var trialOver = nowFormatted > periodEnd  ? true : false;
    }

    return (
        <Fragment>
            <Paper sx={{ padding: 2, mb: 2, bgcolor: 'primary.main' }}>
                <Typography variant="h5" sx={{ m: 1 }}>Welcome to NBFire</Typography>
            </Paper>
            { !isSignedIn &&
                <Paper sx={{ padding: 2, mb: 2}}>
                    <UserAccount/>
                </Paper>
            }
            {trialOver &&
                <Paper sx={{ padding: 2, mb: 2}}>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Alert sx={{mt: 1, mb: 1}} severity="error">Trial period has expired, please upgrade account to continue use of NBFire</Alert>
                    </Box>
                </Paper>
            }
            { isSignedIn &&
                <Paper sx={{ padding: 2, mb: 2}}>
                    <NotebooksSummary/>
                </Paper>
            }
        </Fragment>
    );
}

export default Homepage;