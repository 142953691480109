import React, { useEffect, useCallback, Fragment, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setRuns, setUpdateRuns } from '../reducers/RunsSlice';
import { GetCognitoToken } from '../utils/TokenUtils';
import { GET_RUNS_URL } from '../utils/UrlUtils';
import { Alert, Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { compareByEpoch, FormatTimestamp } from '../utils/DateUtils';
import DownloadOutputNotebookButton from './DownloadOutputNotebookButton';
import { useSnackbar } from 'notistack';

const POLLING_TIME = 10000 // 10s

const RunRow = (props) => {
    const { row, isNotebookRequest } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <Fragment>
        {row && 
          <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              {!isNotebookRequest && <TableCell scope="row">{row.notebookId}</TableCell>}
              <TableCell>{FormatTimestamp(row.createdDate)}</TableCell>
              <TableCell>{FormatTimestamp(row.finishedDate)}</TableCell>
              <Tooltip title={row.errorMessage ? "Error processing run, expand row for more detail" : ""}>
                <TableCell sx={{ ...(row.errorMessage && {color: "error.main"})}}>{row.state}</TableCell>
              </Tooltip>
              <TableCell align="right">{row.outputNotebook && <DownloadOutputNotebookButton runId={row.id} />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  { row.errorMessage &&
                    <Alert sx={{mt: 1}} severity="error">{row.errorMessage}</Alert>
                  }
                  { row.parameters && row.parameters.length > 0 ?
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="body2" gutterBottom component="div">Parameters:</Typography>
                      <Table size="small" aria-label="parameters">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.parameters && row.parameters.map((param) => (
                            <TableRow key={param.name}>
                              <TableCell scope="row">{param.name}</TableCell>
                              <TableCell>{param.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    :
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="body2" gutterBottom component="div">Notebook does not have any parameters</Typography>
                    </Box>
                  }
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        }
      </Fragment>
    );
  }

const RunsSummary = props => {
    const { isNotebookRequest } = props;

    const theme = useTheme();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const notebook = useSelector(state => state.notebookDetail.value);
    const runs = useSelector(state => state.runs.value);
    var sortedRuns = [...runs];
    sortedRuns.sort(compareByEpoch);
    const updateRuns = useSelector(state => state.runs.updateRuns);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const checkRunsState = useCallback(runs => {
      const isprocessing = runs.some(run => {
        return run.state !== "STOPPED" && run.state !== "FAILED";
      });
      if(isprocessing) {
        const timer = setTimeout(() => {
          dispatch(setUpdateRuns(true));
        }, POLLING_TIME);
        return () => clearTimeout(timer);
      }
    }, [dispatch]);

    const getRuns = useCallback(() => {
        GetCognitoToken().then(jwt => {
            axios.get(GET_RUNS_URL, {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true,
                params: { 
                    ...(isNotebookRequest && {notebookId: notebook.id})
                }
            }).then(response => {
                const runs = response.data.Items;
                dispatch(setRuns(runs));
                dispatch(setUpdateRuns(false));
                checkRunsState(runs);
            }).catch(error => {
              enqueueSnackbar(`Error Fetching Runs`, { variant: 'error' });
            });
        });
    }, [dispatch, isNotebookRequest, checkRunsState, enqueueSnackbar, notebook.id]);

    useEffect(() => {
        getRuns();
    }, [getRuns]);

    useEffect(() => {
        if (updateRuns) {
            getRuns();
        }
    }, [updateRuns, getRuns]);

    const refreshRuns = () => {
      dispatch(setUpdateRuns(true));
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedRuns.length) : 0;

    return (
        <Paper sx={{ padding: 2, height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6" sx={{ m: 0 }}>Runs</Typography>
              <IconButton color="primary" aria-label="Refresh Runs" onClick={refreshRuns} disabled={updateRuns} sx={{ p: 0 }}>
                  <RefreshIcon />
              </IconButton>
            </Box>
            <TableContainer component={Paper} sx={{backgroundColor: theme.palette.background.default}}>
                <Table aria-label="collapsible table" size="small" >
                    <TableHead>
                        <TableRow sx={{ "& th": { fontWeight : 600 }}}>
                            <TableCell />
                            {!isNotebookRequest && <TableCell>Notebook ID</TableCell>}
                            <TableCell>Started</TableCell>
                            <TableCell>Finished</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell align="right">Output</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRuns && sortedRuns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((run, index) => (
                            <RunRow key={run.id} row={run} isNotebookRequest={isNotebookRequest}/>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={!isNotebookRequest ? 6 : 5}/>
                          </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedRuns.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default RunsSummary;