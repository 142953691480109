import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: "",
    isSignedIn: false
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action.payload
    }
  }
})

export const { setEmail, setIsSignedIn } = userSlice.actions

export default userSlice.reducer