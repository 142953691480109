import { createSlice } from '@reduxjs/toolkit'

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    value: [],
    updateSchedules: false
  },
  reducers: {
    setSchedules: (state, action) => {
      state.value = action.payload
    },
    setUpdateSchedules: (state, action) => {
      state.updateSchedules = action.payload
    }
  }
})

export const { setSchedules, setUpdateSchedules } = schedulesSlice.actions

export default schedulesSlice.reducer