import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { setUpdateNotebooks } from '../reducers/notebooksSlice';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { ADD_NOTBOOK_URL } from '../utils/UrlUtils';
import { GetCognitoToken } from '../utils/TokenUtils';
import NotebookSelector from './NotebookSelector';
import { useSnackbar } from 'notistack';

const AddNotebook = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [notebook, setNotebook] = useState("");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const updateDescription = event => {
        setDescription(event.target.value);
    }

    const buildRequestData = () => {
        const requestData = {
            name: name,
            description: description
        };
        return requestData;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        GetCognitoToken().then(jwt =>{
            axios.post(ADD_NOTBOOK_URL, buildRequestData(), {
                headers: {"Authorization" : `Bearer ${jwt}`},
                crossDomain: true
            }).then(response => {
                axios.put(response.data.presignedUrl, notebook, {
                    headers: {
                        "Content-Type": 'binary/octet-stream'
                    }
                }).then(response => {   
                    dispatch(setUpdateNotebooks(true));
                    enqueueSnackbar(`Sucesfully Uploaded ${name}`, { variant: 'success' });
                }).catch(error => {
                    enqueueSnackbar(`Error Uploading ${name}`, { variant: 'error' });
                });
            }).catch(error => {
                enqueueSnackbar(`Error Uploading ${name}`, { variant: 'error' });
            });
        });
    };

    return (
        <Paper sx={{ padding: 2, mb: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Upload Notebook:</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <NotebookSelector updateName={setName} updateNotebook={setNotebook}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description-input"
                            name="description"
                            label="Description"
                            type="text"
                            value={description}
                            onChange={updateDescription}
                            sx={{ width: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
}

export default AddNotebook;