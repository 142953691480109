import React, { Fragment } from 'react';
import axios from 'axios';
import { GetCognitoToken } from '../utils/TokenUtils';
import format from 'string-template';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GET_OUTPUT_NOTEBOOK_URL } from '../utils/UrlUtils';

const DownloadOutputNotebookButton = props => {
    const { runId } = props;
    const getNotebookUrl = format(GET_OUTPUT_NOTEBOOK_URL, { runId: runId });

    const handleDownload = () => {
        GetCognitoToken().then(jwt => {
            axios.get(getNotebookUrl, {
                headers: { "Authorization": `Bearer ${jwt}` },
                crossDomain: true
            }).then((response) => {
                window.open( response.data.downloadUrl, '_blank' );
            });
        });
    };

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" onClick={handleDownload}>
                <FileDownloadIcon />
            </IconButton>
        </Fragment>
    );
}

export default DownloadOutputNotebookButton;




