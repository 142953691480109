import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, Radio, RadioGroup, Tab, Tabs, TextField, Typography } from "@mui/material";
import TabPanel from '../utils/TabPanel';

function ScheduleBuilder(props) {
    const { cronSchedule, setCronSchedule } = props;

    const getCronSchedule = useCallback(() => {
        return cronSchedule;
    }, [cronSchedule]);

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const defaultScheduleValues = {
        atMinutes: "0",
        everyMinutes: "15",
        atHours: "7",
        everyHours: "6",
        atDays: ["MON"],
        atDates: ["1"]
    };

    const daysData = [
        { name: "Mon", value: "MON" },
        { name: "Tues", value: "TUE" },
        { name: "Weds", value: "WED" },
        { name: "Thurs", value: "THU" },
        { name: "Fri", value: "FRI" },
        { name: "Sat", value: "SAT" },
        { name: "Sun", value: "SUN" }
    ];

    const datesData = [
        { name: "1st", value: "1" },
        { name: "2nd", value: "2" },
        { name: "3rd", value: "3" },
        { name: "4th", value: "4" },
        { name: "5th", value: "5" },
        { name: "6th", value: "6" },
        { name: "7th", value: "7" },
        { name: "8th", value: "8" },
        { name: "9th", value: "9" },
        { name: "10th", value: "10" },
        { name: "11th", value: "11" },
        { name: "12th", value: "12" },
        { name: "13th", value: "13" },
        { name: "14th", value: "14" },
        { name: "15th", value: "15" },
        { name: "16th", value: "16" },
        { name: "17th", value: "17" },
        { name: "18th", value: "18" },
        { name: "19th", value: "19" },
        { name: "20th", value: "20" },
        { name: "21st", value: "21" },
        { name: "22nd", value: "22" },
        { name: "23rd", value: "23" },
        { name: "24th", value: "24" },
        { name: "25th", value: "25" },
        { name: "26th", value: "26" },
        { name: "27th", value: "27" },
        { name: "28th", value: "28" },
        { name: "29th", value: "29" },
        { name: "30th", value: "30" },
        { name: "31st", value: "31" }
    ];

    const [scheduleBuilderValues, setScheduleBuilderValues] = useState(defaultScheduleValues);
    const [minuteType, setMinuteType] = React.useState('atMinutes');
    const [hoursType, setHoursType] = React.useState('atHours');
    const [dateType, setDateType] = React.useState('allDates');

    const updateScheduleBuilderValues = (event, param) => {
        setScheduleBuilderValues({ ...scheduleBuilderValues, [param]: event.target.value });
    }

    const updateScheduleBuilderArrays = (event, param, value) => {
        var arrayToUpdate = scheduleBuilderValues[param];
        if (event.target.checked) {
            arrayToUpdate.push(value);
        } else {
            const index = arrayToUpdate.indexOf(value);
            if (index > -1) {
                arrayToUpdate.splice(index, 1);
            }
        }
        setScheduleBuilderValues({ ...scheduleBuilderValues, [param]: arrayToUpdate });
    }

    const handleMinuteTypeChange = (event) => {
        setMinuteType(event.target.value);
    };

    const handleHoursTypeChange = (event) => {
        setHoursType(event.target.value);
    };

    const handleDateTypeChange = (event) => {
        setDateType(event.target.value);
    };

    useEffect(() => {
        const cronParts = getCronSchedule().split(' ');
        if (minuteType === 'atMinutes') {
            cronParts[0] = scheduleBuilderValues.atMinutes;
        } else if (minuteType === 'everyMinutes') {
            cronParts[0] = `*/${scheduleBuilderValues.everyMinutes}`;
        }

        if (hoursType === 'allHours') {
            cronParts[1] = "*";
        } else if (hoursType === 'atHours') {
            cronParts[1] = scheduleBuilderValues.atHours;
        } else if (hoursType === 'everyHours') {
            cronParts[1] = `*/${scheduleBuilderValues.everyHours}`;
        }

        if (dateType === 'allDates') {
            cronParts[2] = "*";
            cronParts[4] = "?";
        } else if (dateType === 'atDays') {
            cronParts[2] = "?";
            cronParts[4] = scheduleBuilderValues.atDays.join(',');
        } else if (dateType === 'atDates') {
            cronParts[2] = scheduleBuilderValues.atDates.join(',');
            cronParts[4] = "?";
        }

        setCronSchedule(cronParts.join(' '));
    }, [scheduleBuilderValues, minuteType, hoursType, dateType, getCronSchedule, setCronSchedule]);

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="schedule builder tabs">
                        <Tab label="Minutes" />
                        <Tab label="Hours" />
                        <Tab label="Days Of Week/Month" />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <FormControl>
                        <RadioGroup
                            value={minuteType}
                            onChange={handleMinuteTypeChange}
                            name="minutes-radio-buttons-group"
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel value="atMinutes" control={<Radio />} />
                                <TextField
                                    id="atMinuteSchedule"
                                    name="atMinuteSchedule"
                                    type="text"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={scheduleBuilderValues.atMinutes}
                                    onChange={(event) => updateScheduleBuilderValues(event, "atMinutes")}
                                    sx={{ width: '100%' }}
                                    disabled={minuteType !== "atMinutes"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">At</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel value="everyMinutes" control={<Radio />} />
                                <TextField
                                    id="everyMinuteSchedule"
                                    name="everyMinuteSchedule"
                                    type="text"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={scheduleBuilderValues.everyMinutes}
                                    onChange={(event) => updateScheduleBuilderValues(event, "everyMinutes")}
                                    sx={{ width: '100%' }}
                                    disabled={minuteType !== "everyMinutes"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Every</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                                    }}
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <FormControl>
                        <RadioGroup
                            value={hoursType}
                            onChange={handleHoursTypeChange}
                            name="hours-radio-buttons-group"
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel value="allHours" control={<Radio />} />
                                <Typography variant="body1" sx={{}}>All Hours</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel value="atHours" control={<Radio />} />
                                <TextField
                                    id="atHoursSchedule"
                                    name="atHoursSchedule"
                                    type="text"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={scheduleBuilderValues.atHours}
                                    onChange={(event) => updateScheduleBuilderValues(event, "atHours")}
                                    sx={{ width: '100%' }}
                                    disabled={hoursType !== "atHours"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">At</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel value="everyHours" control={<Radio />} />
                                <TextField
                                    id="everyHoursSchedule"
                                    name="everyHoursSchedule"
                                    type="text"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    value={scheduleBuilderValues.everyHours}
                                    onChange={(event) => updateScheduleBuilderValues(event, "everyHours")}
                                    sx={{ width: '100%' }}
                                    disabled={hoursType !== "everyHours"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Every</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                    }}
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <FormControl>
                        <RadioGroup
                            value={dateType}
                            onChange={handleDateTypeChange}
                            name="date-radio-buttons-group"
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel value="allDates" control={<Radio />} />
                                <Typography variant="body1" sx={{}}>Every Day</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                                <FormControlLabel value="atDays" control={<Radio />} />
                                <FormGroup sx={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems:'center'}}>
                                    {daysData.map(function (day) {
                                        return (
                                            <FormControlLabel key={"atdays-" + day.name} control={
                                                <Checkbox
                                                    checked={scheduleBuilderValues.atDays.includes(day.value)}
                                                    onChange={(event) => updateScheduleBuilderArrays(event, "atDays", day.value)}
                                                    inputProps={{ 'aria-label': day.name }}
                                                    disabled={dateType !== "atDays"}
                                                />
                                            } label={day.name} />
                                        )
                                    })}
                                </FormGroup>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <FormControlLabel value="atDates" control={<Radio />} />
                                <FormGroup sx={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems:'center'}}>
                                    {datesData.map(function (date) {
                                        return (
                                            <FormControlLabel key={"atdates-" + date.name} control={
                                                <Checkbox
                                                    checked={scheduleBuilderValues.atDates.includes(date.value)}
                                                    onChange={(event) => updateScheduleBuilderArrays(event, "atDates", date.value)}
                                                    inputProps={{ 'aria-label': date.name }}
                                                    disabled={dateType !== "atDates"}
                                                />
                                            } label={date.name} />
                                        )
                                    })}
                                </FormGroup>
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </TabPanel>
            </Box>
        </Fragment>
    );
}

export default ScheduleBuilder;