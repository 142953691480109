import React, { Fragment } from 'react';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, Button, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import AccountDetails from './AccountDetails';
import './CognitoStyles.css';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
});

const theme = {
    name: 'pretty-princess',
    tokens: {
      colors: {
        background: {
          primary: { value: 'hotpink' }
        }
      }
    }
  }

const formFields = {
    signIn: {
        username: {
            placeholder: 'Email',
        },
    },
    signUp: {
        username: {
            placeholder: 'Email',
        },
    },
    resetPassword: {
        username: {
            placeholder: 'Email:',
        },
    },
};

const UserAccount = props => {
    const authState= "signIn";

    const email = useSelector(state => state.user.email);
    const isSignedIn = useSelector(state => state.user.isSignedIn);
    const signUserOut = () => {
        Auth.signOut();
    }

    return (
        <Fragment>
            {isSignedIn ?
                <Fragment>
                    <Paper sx={{ padding: 2, mb: 3 }}>
                        <AccountDetails userEmail={email}/>
                        <Button variant="contained" onClick={signUserOut} sx={{ mb: 3 }}>Sign out</Button>
                    </Paper>
                    {/*<Paper sx={{ padding: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Subscription Types</Typography>
                        <Subscribe />
                    </Paper>*/}
                </Fragment>
                :
                <Box display="flex" justifyContent="center" alignItems="top" minHeight="100vh" sx={{ p: 6, m: 0 }}>
                    <Authenticator initialState={authState} formFields={formFields} theme={theme}>
                        {() => (
                            <Fragment sx={{ display: 'none'  }}>
                            </Fragment>
                        )}
                    </Authenticator>
                    
                </Box>
            }
        </Fragment>
    );
}

export default UserAccount;