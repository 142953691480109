import React, { Fragment } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserMenu = props => {
    const { width } = props;
    const userName = useSelector(state => state.user.email);

    return (
        <Fragment>
            { userName && userName.length > 0 ?
            <List sx={{ width: width }}>
                <ListItem button component={Link} to="/account" >
                    <ListItemIcon>
                        <AccountCircleIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ overflow: "hidden", textOverflow: "ellipsis"}}>Account</ListItemText>
                </ListItem>
            </List>
            :
            <List sx={{ width: width }}>
                <ListItem button component={Link} to="/account" >
                    <ListItemIcon>
                        <AccountCircleIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ overflow: "hidden", textOverflow: "ellipsis"}}>login</ListItemText>
                </ListItem>
            </List>
            }
        </Fragment>
    );
}

export default UserMenu;