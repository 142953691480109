import { configureStore } from '@reduxjs/toolkit';
import notebooksReducer from './app/reducers/notebooksSlice';
import userReducer from './app/reducers/userSlice';
import notebookDetailReducer from './app/reducers/notebookDetailSlice'
import runsReducer from './app/reducers/RunsSlice';
import schedulesReducer from './app/reducers/schedulesSlice';
import scheduleDeleteReducer from './app/reducers/scheduleDeleteSlice';
import userDetailsReducer from './app/reducers/userDetailsSlice';
import accountTypesReducer from './app/reducers/accountTypesSlice';

export default configureStore({
  reducer: {
    notebooks: notebooksReducer,
    user: userReducer,
    notebookDetail: notebookDetailReducer,
    runs: runsReducer,
    schedules: schedulesReducer,
    scheduleDelete: scheduleDeleteReducer,
    userDetails: userDetailsReducer,
    accountTypes: accountTypesReducer
  }
})